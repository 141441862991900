






















import {Component, Vue} from "vue-property-decorator";
import NavDrawer from "@/components/NavDrawer.vue";
import App from "@/App.vue";
import CommonModule from "@/common/module/CommonModule";
import {getModule} from "vuex-module-decorators";

@Component({
    components: {NavDrawer}
})
export default class MainLayout extends Vue {
    showDrawer: boolean = false;

    get app(): App {
        return this.$parent as App;
    }

    get title(): string {
        return this.app.pageTitle;
    }

    get commonData(): CommonModule {
        return getModule(CommonModule, this.$store);
    }


    get isLoggedIn(): boolean {
        return this.commonData.loggedIn;
    }


    get siteName(): string {
        const data: CommonModule = this.commonData;
        return data.needsLogin ? "MyPBXTools" : data.userData?.company ?? '';
    }

}
