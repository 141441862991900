import NumUtils from "@/common/utils/NumUtils";

export default class DateTimeUtils {
    public static toTimeString(d: Date) {
        return `${NumUtils.toStringFixedDig(d.getHours(), 2)}:${NumUtils.toStringFixedDig(d.getMinutes(), 2)}`;
    }

    public static dateEquals(a: Date, b: Date) {
        return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
    }
}