
























































import {Component, ModelSync, Vue, Watch} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import UserData from "@/common/data/UserData";
import UserPerms from "@/common/UserPerms";
import CommonModule from "@/common/module/CommonModule";
import DateTimeUtils from "@/common/DateTimeUtils";
import UserForm from "@/components/UserForm.vue";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";

interface NavItem {
    type: 'link' | 'separator',
    title?: string,
    icon?: string,
    path?: string
}

@Component({
    components: {UserForm}
})
export default class NavDrawer extends Vue {
    showUserSettings: boolean = false;

    async submitUserAsync() {
        const data = this.userData!;
        await MyPbxToolsApi.instance.doUserAction('update', data);
    }

    @ModelSync('modelValue', 'update:modelValue', {type: Boolean})
    showDrawerInternal!: boolean;

    static readonly DefaultStartItems: NavItem[] = [
        {type: "link", title: "Home", icon: "mdi-home", path: '/'},
        {type: "separator"},
    ];
    static readonly DefaultEndItems: NavItem[] = [
        {type: "link", title: "Log Out", icon: "mdi-logout", 'path': '/logout'}
    ];

    static readonly CanManageUserItems: NavItem[] = [

        {type: "link", title: "User Manager", icon: "mdi-account-multiple-outline", path: '/user-manager'},
        {type: "separator"},
    ];

    static readonly PowerboardUserItems: NavItem[] = [
        {type: "link", title: "Powerboards", icon: "mdi-grid", path: '/pb/boards'},
        {type: "link", title: "Powerboard Instances", icon: "mdi-server", path: '/pb/instances'},
        {type: "link", title: "Powerboard Templates", icon: "mdi-pencil-ruler", path: '/pb/templates'},
        {type: "separator"},
    ];

    get expireTime(): string {
        const d = this.commonModule.api.expireDate;
        if (d == null)
            return "";
        const now = new Date();
        return DateTimeUtils.dateEquals(now, d) ?
            now.toLocaleTimeString() : d.toLocaleString();
    }

    submitUser() {
        this.submitUserAsync().catch(console.error);
    }

    get userData(): UserData | null {
        return this.commonModule.userData;
    }

    get userPerms(): UserPerms | null {
        return this.commonModule.userPerms;
    }

    get commonModule(): CommonModule {
        return getModule(CommonModule, this.$store);
    }

    get items(): NavItem[] {
        return Array.from(this.rawItems());

    }

    * rawItems() {

        for (const start of NavDrawer.DefaultStartItems) {
            yield start;
        }

        const perms = this.userPerms;
        if (perms !== null && perms.manageUsers) {
            for (const item of NavDrawer.CanManageUserItems) {
                yield item;
            }
        }
        if (perms !== null && perms.admin) {
            for (const item of NavDrawer.PowerboardUserItems) {
                yield item;
            }
        }
        for (const end of NavDrawer.DefaultEndItems) {
            yield end;
        }
    }

    @Watch('$store.state.userPerms', {deep: true})
    onUserPermsChanged() {
        console.log('state changed!')
    }
}
